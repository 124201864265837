import { vipAxios, Response } from './index';
// import { AxiosError } from 'axios';
export interface Logs1CountType {
  count4all: number; // 23.02.01 SCH
  count4using: number;
  count4expired: number;
  count4return: number;
}
export interface Logs2CountType {
  count4all: number;
  count4using: number;
  count4expired: number;
  count4return: number;
}
export interface Logs1Type {
  id: number;
  created_at: string;
  shipping_at: string; // 23.01.05 SCH
  received_at: string; // 23.01.31 SCH
  expired_at: string;
  returned_at: string; // 23.01.31 SCH
  overdue_at: string; // 23.01.05 SCH
  svpt_id: number;
  svpt_name: string;
  renter_id: number;
  renter_name: string;
  renter_mobile: string;
  return_user_id: number;
  return_name: string;
  mobile_number: string;
  chs_id: number;
  channel_name: string; // 21.12.15 SCH
  rent_type: number;
  rentTypeName: string; // 22.04.22 SCH
  paid_amount: number; // 22.04.22 SCH
  paid_way: number;
  paidWayName: string; // 22.04.22 SCH
  eco_id: number;
  eco_short_code: string; // 22.04.22 SCH
  ec_partner_id: number;
  ec_partner_name: string; // 22.04.22 SCH
  count_return: number;
  count_using: number;
  count_expired: number;
}
/**
 * 取得歸還記錄!!
 */
export const listUserRentLogs = async (
  token: string,
  brandID: number,
  userID: number,
  filterSelf: number,
  sort: string, // 22.05.30 SCH
): Promise<Response<Logs1Type[]>> => {
  try {
    const { data } = await vipAxios.request<Logs1Type[]>({
      url: '/api/member/listUserRentLogs',
      method: 'POST',
      data: {
        brand_id: brandID,
        user_id: userID,
        filterSelf: filterSelf,
        sort: sort, // 22.05.30 SCH
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data }; // 21.12.09 SCH
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
export const countUserRentLogs = async (
    token: string,
    brandID: number,
    userID: number,
    filterSelf: number,
    sort: string, // 22.05.30 SCH
): Promise<Response<Logs1CountType>> => {
  try {
    const { data } = await vipAxios.request<Logs1CountType>({
      url: '/api/member/countUserRentLogs',
      method: 'POST',
      data: {
        brand_id: brandID,
        user_id: userID,
        filterSelf: filterSelf,
        sort: sort, // 22.05.30 SCH
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data }; // 22.04.22 SCH
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
/**
 * 取得租借記錄!! // 22.04.22 SCH
 */
export const listRentDataLogs = async (
    token: string,
    brandID: number,
    userID: number,
    filter: string,
    sort: string, // 22.05.30 SCH
): Promise<Response<Logs1Type[]>> => {
  try {
    const { data } = await vipAxios.request<Logs1Type[]>({
      url: '/api/member/listRentDataLogs',
      method: 'POST',
      data: {
        brand_id: brandID,
        user_id: userID,
        filter: filter,
        sort: sort, // 22.05.30 SCH
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
export const countRentDataLogs = async (
    token: string,
    brandID: number,
    userID: number,
    filter: string
): Promise<Response<Logs2CountType>> => {
  try {
    const { data } = await vipAxios.request<Logs2CountType>({
      url: '/api/member/countRentDataLogs',
      method: 'POST',
      data: {
        brand_id: brandID,
        user_id: userID,
        filter: filter,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface UserCouponsData {
  id: number;
  coupon_id: number;
  title: string;
  description: string;
  start_at: string;
  expired_at: string;
  created_at: string;
  picture_url: string; // 22.05.29 SCH
}
/**
 * 取得(線上)電商優惠券(Coupons)!!
 */
export const listUserCoupons = async (
  token: string,
  brandID: number,
  userID: number,
  filter: string, // 22.05.29 SCH
  sort: string, // 22.05.29 SCH
): Promise<Response<UserCouponsData[]>> => {
  try {
    const { data } = await vipAxios.request<UserCouponsData[]>({
      url: '/api/member/listUserCoupons',
      method: 'POST',
      data: {
        brand_id: brandID,
        user_id: userID,
        filter: filter, // 22.05.29 SCH
        sort: sort, // 22.05.29 SCH
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: data }; // 21.12.13 SCH
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface UserVoucherLogsData {
  id: number;
  userVoucherLog_id: number;
  user_rent_log_id: number;
  return_user_id: number;
  chs_id: number;
  svpt_id: number;
  svpt_info: string;
  created_at: string;
  use_type: number; // 21.12.22 SCH
  redeem_code: string;
  giftcard_code: string;
  redeem_url: string;
  pin_code: string;
  used: boolean; // 21.12.29 SCH
  picture_url: string; // 22.05.29 SCH
}
/**
 * 取得歸還者兌換券
 */
export const listUserVoucherLogs = async (
  token: string,
  brandID: number,
  userID: number,
  filter: string, // 22.05.29 SCH
  sort: string, // 22.05.29 SCH
): Promise<Response<UserVoucherLogsData[]>> => {
  try {
    const { data } = await vipAxios.request<UserVoucherLogsData[]>({
      url: '/api/member/listUserVoucherLogs',
      method: 'POST',
      data: {
        brand_id: brandID,
        return_user_id: userID,
        filter: filter, // 22.05.29 SCH
        sort: sort, // 22.05.29 SCH
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: data }; // 21.12.29 SCH
  } catch (error: any) {
    console.log(error); // 21.12.29 SCH
    return { error, data: undefined };
  }
};

interface StoreRes<Data> {
  error?: any;
  data: Data;
  meta?: {
    current_page: number;
    per_page: number;
    total: number;
  };
}

export interface Product {
  id: number;
  brand_name: string;
  name: string;
  recommend: 0 | 1;
  hot: 0 | 1;
  price: number;
  discount: number | null;
  images: { id: number; url: string }[];
  available_quantity: number;
  inventory_quantity: number;
  maximum_quantity: number;
  description: string;
  note: string;
  shops: { id: number; name: string }[];
  shop_groups: { id: number; name: string }[];
  package: {
    package_unit_price: number;
    product_id: number;
    product_name: string;
    product_quantity: number;
  }[];
  type_deduct: 1 | 2 | 3;
  period_discount: 1 | 2;
  discount_start_date: string;
  discount_end_date: string;
  period_sales: 1 | 2;
  sales_start_date: string;
  sales_end_date: string;
}

export interface Category {
  id: number;
  name: string;
}
/**
 * 獲取分類列表
 * @param token token
 */
export const getCategories = async (
  token: string
): Promise<Response<Category[]>> => {
  try {
    const { data } = await vipAxios.request<StoreRes<Category[]>>({
      url: `/api/member/category`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface PaymentInfo {
  name: string | null;
  phone: string | null;
  email: string | null;
  location: string | null;
  order_cost: number;
  zip_code: string | null;
  address: string | null;
  carrier_option: 'phone' | 'company' | 'member' | null;
  carrier_value: string | null;
  gui_title: string | null;
  gui_number: string | null;
}
/**
 * 取得 付款資訊
 * @param token
 * @param orderID 訂單ID
 * @apinums 590
 * @returns
 */
export const getPaymenyInfo = async (
  token: string
): Promise<Response<PaymentInfo>> => {
  try {
    const { data } = await vipAxios.request<StoreRes<PaymentInfo>>({
      url: `/api/member/payment`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { data: data.data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
