import { ReactComponent as ArrowRightSVG } from 'assets/icon-arrow-right.svg';
import { ReactComponent as AvatarSVG } from 'assets/icon-avatar.svg';
import Tabs, { Tab } from 'components/Tabs';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, Outlet, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { useAuth } from 'context/Auth'; // 21.12.09 SCH
import { GlobalContext4User } from '../../storage/user';
import { countRentDataLogs, countUserRentLogs } from '../../api/logs'; // 22.04.22 SCH
import { Logs1CountType, Logs2CountType } from '../../api/logs';
import { format as dateFormat } from 'date-fns'; // 22.05.30 SCH
import Dialog from 'components/Dialog'; // 22.11.23 SCH

const MemberCenter = () => {
  const { accessToken, me } = useAuth(); // 22.04.22 SCH
  const [activeTab, setActiveTab] = useState<number>(1);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // @ts-ignore // 22.04.19 SCH, Add storage4user['meUserStorage']
  const { meUserStorage } = useContext(GlobalContext4User);
  const { user, user_role, user_type } = meUserStorage; // 22.11.23 SCH
  const [visible4set, setVisible4set] = useState<boolean>(false); // 22.11.23 SCH
  const [logs1, setLogs1] = useState<Logs1CountType>();
  const [logs2, setLogs2] = useState<Logs2CountType>();
  const filterSelf = useRef<number>(2); // 22.05.30 SCH, <2:all | 1:self | 0:other>
  const sortRef = useRef<string>('descend'); // 22.05.30 SCH
  const count4using = useRef<number>(0); // 22.05.30 SCH
  const count4expired = useRef<number>(0); // 22.05.30 SCH
  const count4all = useRef<number>(0); // 22.05.30 SCH
  const count4return = useRef<number>(0); // 22.05.30 SCH
  const [imgUrl, setImgUrl] = useState<string>(''); // 22.07.07 SCH
  const imgPreUrl =
    'https://packageplusmainsys.s3-ap-northeast-1.amazonaws.com/';

  useEffect(() => {
    fetchUserRentLogs();
    fetchRentDataLogs('all'); // 23.01.05 SCH
    // setActiveTab(1);
    if (pathname.indexOf('return') !== -1) {
      setActiveTab(1);
    } else if (pathname.indexOf('payment') !== -1) {
      setActiveTab(2);
    } else {
      setActiveTab(0);
    }
    consoleLog();
  }, [pathname]);


  const consoleLog = () => {
    console.log('me.mobile_number:', me?.mobile_number); // OK
    console.log('user.realname:', user?.realname); // OK
    console.log('user_role', user_role); // undefined ??
    console.log(user); // OK // 22.11.23 SCH
    console.log(me); // OK by API getMe (not API login)
  };

  const fetchUserRentLogs = async () => {
    try {
      const { data } = await countUserRentLogs(
        accessToken!,
        1,
        me?.id!,
        filterSelf.current,
        sortRef.current
      );
      if (!data) {
        return 'Have not any return logs !!';
      } else {
        setLogs1(data);
        count4using.current = data.count4using;
        console.log('using:', data.count4using);
        count4expired.current = data.count4expired;
        console.log('expired:', data.count4expired);
        count4all.current = data.count4all;
        console.log('all:', data.count4all);
        count4return.current = data.count4return;
        console.log('returned', data.count4return);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchRentDataLogs = async (filter: string) => {
    try {
      const { data } = await countRentDataLogs(
        accessToken!,
        1,
        me?.id!,
        filter
      );
      if (!data) {
        return 'Have not any rent logs !!';
      } else {
        setLogs2(data);
        // 23.01.05 SCH
        count4using.current = data.count4using;
        console.log('using:', data.count4using);
        count4expired.current = data.count4expired;
        console.log('expired:', data.count4expired);
        count4all.current = data.count4all;
        console.log('all:', data.count4all);
        count4return.current = data.count4return;
        console.log('returned', data.count4return);
      }
      setupImage(); // 22.07.07 SCH
    } catch (error) {
      console.log(error);
    }
  };

  const setupImage = () => {
    // 22.07.11 SCH, 處理 imgUrl 內容!!
    if (user.photo_url && user.photo_url.length > 9) {
      if (user.photo_url.indexOf('http') < 0) {
        setImgUrl(imgPreUrl + user.photo_url);
      } else {
        setImgUrl(user.photo_url);
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const onConfirm = () => {
    // 22.11.23 SCH
    setVisible4set(false);
  };
  const onCancel = () => {
    // 22.11.23 SCH
    setVisible4set(false);
  };

  return (
    <div className={styles.memberCenter}>
      <div className={styles.header}>
        <div className={styles.info}>
          <div
            className={styles.avatar}
            onClick={() => {
              navigate('profile');
            }}
          >
            {imgUrl.length <= 9 ? (
              <AvatarSVG />
            ) : (
              <img src={imgUrl} height="40" alt="Image.Url" />
            )}
          </div>
          <div className={styles.detail}>
            <h5
              onClick={() => {
                navigate('profile');
              }}
            >
              {/* {me?.mobile_number} 22.04.21 SCH */}
              {user.realname !== '' ? user.realname : user.mobile_number}
            </h5>

            <div className={styles.extra}>
              <span>配客嘉會員</span>
              {/* <button>
                <span>方案說明</span>
                <ArrowRightSVG />
              </button> */}
              <button
                onClick={() => {
                  (user.role > 1) ?
                    navigate('/payment/member') 
                    : setVisible4set(false)
                 }}
              >
                <span>付款設定</span>
                <ArrowRightSVG />
              </button>
            </div>
          </div>
        </div>
        {/* <div className={styles.additionInfo}>
          <span>循環購物袋</span>
          <div className={styles.comingSoon}>
            <span>Coming soon</span>
          </div>
        </div> */}
        <div className={styles.additionInfo}>
          <div className={styles.usageInfo}>
            <div className={styles.column}>
              <span className={styles.num}>{logs1?.count4using ?? count4using.current}</span>
              <span>使用中</span>
            </div>
            <div className={styles.column}>
              <span className={styles.num}>{logs1?.count4expired ?? count4expired.current}</span>
              <span>已逾期</span>
            </div>
            <div className={styles.column}>
              <span className={styles.num}>{logs1?.count4return ?? count4return.current}</span>
              <span>已歸還</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.tabContainer}>
        <Tabs value={activeTab} onChange={handleChange}>
          <Tab label="租借記錄" component={Link} to={`./`} />
          <Tab label="歸還記錄" component={Link} to={`return`} />
          <Tab
            disabled={true}
            label="扣款記錄"
            component={Link}
            to={`payment`}
          />
        </Tabs>
      </div>
      <div className={styles.container}>
        <Outlet />
      </div>

      <Dialog
        visible={visible4set}
        onConfirm={onConfirm}
        onCancel={onCancel}
        message={
          <div className={styles.dialogHeader}>
            <h3>
              {(user.role > 1)
                ? '符合開放條件'
                : '尚未開放設定'}
            </h3>
            <p>... 功能測試中 ... </p>
          </div>
        }
        cancelText={'Exit'}
        okText={'知道了'}
        cancelable={true}
      />
    </div>
  );
};

export default MemberCenter;
